export interface SocialReferent {
  id: string
  name: string
  surname: string
  phone: string
  email: string
  pictureUrl: string
  postalCode: string
  academicTitle: string
  activeFrom: string
  collegiateNo: string
  country: string
  employeeState: number
  locality: string
  region: string
  street: string
  socialReferentType: number
  canSuspendSr: boolean
  fullName?: string
  professionalDescEs: string
  professionalDescCa: string
}
