import { APPOINTMENT_TYPES } from '@shared/data-access'
import { Absences, Care, GenericAnalysis } from '../../components'

export const APPOINTMENT_MODAL = Object.freeze({
  [APPOINTMENT_TYPES.ABSENCES]: Absences,
  [APPOINTMENT_TYPES.CONSULTATION]: Care,
  [APPOINTMENT_TYPES.INITIAL_ANALYSIS]: Care,
  [APPOINTMENT_TYPES.INITIAL_REPORT]: Care,
  [APPOINTMENT_TYPES.INITIAL_REPORT_FACE_TO_FACE]: Care,
  [APPOINTMENT_TYPES.MONITORING_REPORT]: Care,
  [APPOINTMENT_TYPES.SERVICE_APPOINTMENT]: Care,
  [APPOINTMENT_TYPES.WELCOME_CALL]: Care,
  [APPOINTMENT_TYPES.GENERIC_ANALYSIS]: GenericAnalysis,
})
