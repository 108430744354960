export interface Person {
  id: null | string
  identification?: null | string
  name?: null | string
  surname?: null | string
  phone?: null | string
  email?: null | string
  postalCode?: null | string
  preferredLanguage?: null | string
  country?: null | string
  region?: null | string
  locality?: null | string
  street?: null | string
  sex?: null | number
  birthDay?: null | string
  personType?: null | number
  fullName?: null | string
}
