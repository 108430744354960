module.exports = {
  colors: {
    'modal-overlay': 'rgba(0, 64, 57, 0.5);',
    'gray-background': '#F8F8F8',
    'dark-gray': '#333333',
    'vcx-error-red': '#BB1313',
    'vcx-dark-white': '#FCFCFC',
    'caixa-primary': '#007EAE',
    'light-grey': '#C1C1C1',
    grayLight: '#E5E5E5',
    'input-disabled-bg': '#E6E8EA',
    'dot-disabled-bg': '#CBD0D8',
    'primary-opacity': 'rgba(0, 64, 57, 0.1)',
    generic: {
      primary: {
        50: '#e6eceb',
        100: '#b0c4c2',
        200: '#8aa7a4',
        300: '#547f7a',
        400: '#336661',
        500: '#004039',
        600: '#003a34',
        700: '#002d28',
        800: '#00231f',
        900: '#001b18',
      },
      secondary: {
        50: '#fff3ef',
        100: '#ffdbcd',
        200: '#ffc9b4',
        300: '#ffb192',
        400: '#ffa17d',
        500: '#ff8a5d',
        600: '#e87e55',
        700: '#b56242',
        800: '#8c4c33',
        900: '#6b3a27',
      },
      tertiary: {
        50: '#fffdfb',
        100: '#fffaf4',
        200: '#fff8ee',
        300: '#fff4e6',
        400: '#ffefe1',
        500: '#ffefda',
        600: '#e8d9c6',
        700: '#b5aa9b',
        800: '#8c8378',
        900: '#6b645c',
      },
      neutral: {
        100: '#F5F5F5',
        200: '#E5E5E5',
        300: '#CCCCCC',
        400: '#B3B3B3',
        500: '#999999',
        600: '#808080',
        700: '#666666',
        800: '#444444',
        900: '#333333',
      },
      informative: {
        50: '#E6FAF9',
        100: '#B0EFEC',
        700: '#00918A',
      },
      success: {
        50: '#E8F8EF',
        700: '#0E8343',
      },
    },
  },
  fontFamily: {
    montserrat: 'Montserrat',
    aeonik: ['Aeonik', 'sans-serif'],
  },
}
