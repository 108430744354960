import { useSearchParams } from 'react-router-dom'
import { snakeCaseToCamelCase } from '../string-formatter'

type Params = {
  [key: string]: unknown
}

export const useQueryParams = (queryParams = []) => {
  const [searchParams] = useSearchParams()
  const params: Params = {}

  queryParams.forEach((param) => {
    const camelCasedParam = snakeCaseToCamelCase(param)
    params[camelCasedParam] = searchParams.get(param)
  })

  return params
}
