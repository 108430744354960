import { Text, View } from '@react-pdf/renderer'
import { areaColorKey, tw } from '../../utils'
import { SubSection } from '../../components/SubSection'
import { TableHeaderBlack } from '../../components/TableHeaderBlack'
import { ScalePerYear } from '../../components/ScalePerYear'
import { TranscriptionScriptPDF } from '../../components/TranscriptionScriptPDF'
import { Numberedlist } from '../../components/NumberedList'
import { TableList } from '../../components/TableList'
import { Banner } from '../../components/Banner/Banner'
import { FollowRecomendations } from '../../components/FollowRecomendations'
import { useReportPdfContext } from '../../report-pdf-provider'
import {
  PHYSICAL_ACTIVITY_KEYS,
  PHYSICAL_ACTIVITY_LINKS,
  PHYSICAL_ACTIVITY_TRANSCRIPTION_MESSAGE,
  PHYSICAL_ACTIVITY_TRANSCRIPTION_OBJETIVE,
  PHYSICAL_ACTIVITY_WORKSHEET_LINKS,
} from './enum'
import { useMemo } from 'react'
import { scoreIsNotAvailable } from '../../utils'
import { DisclaimerBanner } from '../../components/DisclaimerBanner'
import { Trans } from 'react-i18next'

const SCALES = { low: 'low', moderate: 'moderate', high: 'high' }

export const SecondPage = () => {
  const { testDate, data, t, person, type } = useReportPdfContext()

  const { scores, recommendations } = data?.results?.initialAnalysisList || {}

  const getActivity = () => {
    let activityIncluded = ''

    Object.values(PHYSICAL_ACTIVITY_KEYS).forEach((activity) => {
      if (recommendations.PHYSICAL_ACTIVITY.includes(activity)) {
        activityIncluded = activity
      }
    })

    return activityIncluded
  }

  const physicalActivityMessage = useMemo(() => {
    if (getActivity() === '') return ' '
    return PHYSICAL_ACTIVITY_TRANSCRIPTION_MESSAGE(person.name)[getActivity()]
  }, [recommendations])

  const physicalActivityObjetive = useMemo(() => {
    if (getActivity() === '') return ' '
    return PHYSICAL_ACTIVITY_TRANSCRIPTION_OBJETIVE[getActivity()]
  }, [recommendations])

  const phisicalActivity = useMemo(() => {
    return getActivity()
  }, [recommendations])

  const table = [
    [
      t(
        'initial-report:sectionThree.secondPage.keyGuidelines.table.aerobic.capacity',
      ),
      t(
        'initial-report:sectionThree.secondPage.keyGuidelines.table.aerobic.objetive',
      ),
      t(
        'initial-report:sectionThree.secondPage.keyGuidelines.table.aerobic.intensity',
      ),
      t(
        'initial-report:sectionThree.secondPage.keyGuidelines.table.aerobic.activityType',
      ),
    ],
    [
      t(
        'initial-report:sectionThree.secondPage.keyGuidelines.table.strengthAndEndurance.capacity',
      ),
      t(
        'initial-report:sectionThree.secondPage.keyGuidelines.table.strengthAndEndurance.objetive',
      ),
      t(
        'initial-report:sectionThree.secondPage.keyGuidelines.table.strengthAndEndurance.intensity',
      ),
      t(
        'initial-report:sectionThree.secondPage.keyGuidelines.table.strengthAndEndurance.activityType',
      ),
    ],
    [
      t(
        'initial-report:sectionThree.secondPage.keyGuidelines.table.flexibility.capacity',
      ),
      t(
        'initial-report:sectionThree.secondPage.keyGuidelines.table.flexibility.objetive',
      ),
      t(
        'initial-report:sectionThree.secondPage.keyGuidelines.table.flexibility.intensity',
      ),
      t(
        'initial-report:sectionThree.secondPage.keyGuidelines.table.flexibility.activityType',
      ),
    ],
    [
      t(
        'initial-report:sectionThree.secondPage.keyGuidelines.table.balanceAndCoordination.capacity',
      ),
      t(
        'initial-report:sectionThree.secondPage.keyGuidelines.table.balanceAndCoordination.objetive',
      ),
      t(
        'initial-report:sectionThree.secondPage.keyGuidelines.table.balanceAndCoordination.intensity',
      ),
      t(
        'initial-report:sectionThree.secondPage.keyGuidelines.table.balanceAndCoordination.activityType',
      ),
    ],
  ]

  const tableTitles = [
    t(
      'initial-report:sectionThree.secondPage.keyGuidelines.table.header.capacity',
    ),
    t(
      'initial-report:sectionThree.secondPage.keyGuidelines.table.header.objetive',
    ),
    t(
      'initial-report:sectionThree.secondPage.keyGuidelines.table.header.intensity',
    ),
    t(
      'initial-report:sectionThree.secondPage.keyGuidelines.table.header.activityType',
    ),
  ]

  const internationalSurvey = [
    {
      label: t(
        'initial-report:sectionThree.secondPage.assesmentScale.table.assesmentDescriptionLabel',
      ),
      content: t(
        'initial-report:sectionThree.secondPage.assesmentScale.table.assesmentDescriptionContent',
      ),
    },
    {
      label: t(
        'initial-report:sectionThree.secondPage.assesmentScale.table.date',
      ),
      content: testDate,
    },
    {
      label: t(
        'initial-report:sectionThree.secondPage.assesmentScale.table.result',
      ),
      content: scoreIsNotAvailable(scores?.IPAQ?.score)
        ? t(`initial-report:resultNotAvailable.ipaq`)
        : t(
            `initial-report:sectionThree.secondPage.assesmentScale.table.resultsList.${scores?.IPAQ?.score}`,
          ),
    },
  ]

  const scales = [
    {
      text: t('initial-report:sectionThree.secondPage.assesmentScale.low'),
      subText: t(
        'initial-report:sectionThree.secondPage.assesmentScale.lessActivity',
      ),
      selected: SCALES[scores?.IPAQ?.score] === SCALES.low,
    },
    {
      text: t('initial-report:sectionThree.secondPage.assesmentScale.moderate'),
      selected: SCALES[scores?.IPAQ?.score] === SCALES.moderate,
    },
    {
      text: t('initial-report:sectionThree.secondPage.assesmentScale.high'),
      subText: t(
        'initial-report:sectionThree.secondPage.assesmentScale.moreActivity',
      ),
      selected: SCALES[scores?.IPAQ?.score] === SCALES.high,
    },
  ]

  const numberedList = [
    {
      title: t(
        'initial-report:sectionThree.secondPage.keyGuidelines.numberedList.aerobics.title',
      ),
      content: (
        <TranscriptionScriptPDF script="initial-report:sectionThree.secondPage.keyGuidelines.numberedList.aerobics.text" />
      ),
    },
    {
      title: t(
        'initial-report:sectionThree.secondPage.keyGuidelines.numberedList.strengthAndEndurance.title',
      ),
      content: (
        <TranscriptionScriptPDF script="initial-report:sectionThree.secondPage.keyGuidelines.numberedList.strengthAndEndurance.text" />
      ),
    },
    {
      title: t(
        'initial-report:sectionThree.secondPage.keyGuidelines.numberedList.flexibility.title',
      ),
      content: (
        <TranscriptionScriptPDF script="initial-report:sectionThree.secondPage.keyGuidelines.numberedList.flexibility.text" />
      ),
    },
    {
      title: t(
        'initial-report:sectionThree.secondPage.keyGuidelines.numberedList.balanceAndCoordination.title',
      ),
      content: (
        <TranscriptionScriptPDF script="initial-report:sectionThree.secondPage.keyGuidelines.numberedList.balanceAndCoordination.text" />
      ),
    },
  ]

  const areaColor = areaColorKey.yellow

  const keepInMindList = t('sectionPhysicalActivity.keepInMind.list', {
    returnObjects: true,
    opName: person.socialReferent.name,
  }).map((translation) => {
    return (
      <Trans components={{ bold: <Text style={tw('font-semibold')} /> }}>
        {translation}
      </Trans>
    )
  })

  const followRecommendations = [
    <TranscriptionScriptPDF script="initial-report:sectionThree.secondPage.followRecommendations.list.one" />,
    <TranscriptionScriptPDF script="initial-report:sectionThree.secondPage.followRecommendations.list.two" />,
    <TranscriptionScriptPDF script="initial-report:sectionThree.secondPage.followRecommendations.list.three" />,
    <TranscriptionScriptPDF script="initial-report:sectionThree.secondPage.followRecommendations.list.four" />,
    <TranscriptionScriptPDF script="initial-report:sectionThree.secondPage.followRecommendations.list.five" />,
  ]

  const hasTable = !recommendations?.PHYSICAL_ACTIVITY.includes(
    'rec_physical_activity_medical_contrindication_excercise',
  )

  return (
    <View style={tw('flex flex-col gap-6')}>
      <View style={tw('px-12')}>

        <SubSection
          title={t(
            'initial-report:sectionThree.secondPage.assesmentScale.title',
          )}
        >
          <View style={tw('flex flex-col gap-6')}>
            <TableHeaderBlack
              title={t(
                'initial-report:sectionThree.secondPage.assesmentScale.table.title',
              )}
              list={internationalSurvey}
            />
            <DisclaimerBanner />
            <ScalePerYear scales={scales} areaColor={areaColor} />
            {physicalActivityMessage}
          </View>
        </SubSection>

        <SubSection
          title={t('initial-report:sectionThree.secondPage.objetives.title')}
          classes={'pb-14'}
        >
          <Text>{physicalActivityObjetive && physicalActivityObjetive}</Text>
        </SubSection>
        <SubSection
          title={t(
            'initial-report:sectionThree.secondPage.keyGuidelines.title',
          )}
        >
          <View style={tw('flex flex-col gap-6')}>
            <Text>
              {t(
                'initial-report:sectionThree.secondPage.keyGuidelines.description',
              )}
            </Text>
            <Numberedlist areaColor={areaColor} column list={numberedList} />
            {PHYSICAL_ACTIVITY_KEYS.recPhysicalActivityPrecontemplation !==
              phisicalActivity && (
              <>
                <Text>
                  {t(
                    'initial-report:sectionThree.secondPage.keyGuidelines.message',
                  )}
                </Text>
                {hasTable && (
                  <View wrap={false}>
                    <TableList
                      tableList={table}
                      tableHeader={tableTitles}
                      areaColor={areaColor}
                    />
                  </View>
                )}
              </>
            )}
          </View>
        </SubSection>
      </View>
      <Banner
        title={t('initial-report:sectionThree.secondPage.keyGuidelines.banner')}
        areaColor={areaColor}
      />

      {/* TODO: Refactor component to divide sections */}

      <FollowRecomendations
        /** New Props for translations array **/
        translateFrom="initial-report:sectionThree.secondPage.linksAndWorkSheet"
        /** Old Props **/
        style={tw('px-12')}
        areaColor={areaColor}
        linksAndWorkSheetText={
          <TranscriptionScriptPDF
            script={`initial-report:sectionThree.secondPage.linksAndWorkSheet.text.${type}`}
            scriptProps={{ patientName: person.name }}
          />
        }
        linksAndWorkSheetTitle={t(
          `initial-report:generic.linksAndWorkSheet.title.${type}`,
        )}
        links={PHYSICAL_ACTIVITY_LINKS[phisicalActivity]}
        workSheetList={PHYSICAL_ACTIVITY_WORKSHEET_LINKS[phisicalActivity]}
        keepInMindList={keepInMindList}
        followRecommendations={followRecommendations}
        followRecommendationsText={t(
          'initial-report:sectionThree.secondPage.followRecommendations.text',
        )}
        omsText={t(
          'initial-report:sectionThree.secondPage.followRecommendations.omsText',
        )}
        worksheetId={`worksheet-physicalActivity`}
      />
    </View>
  )
}
