export const GENERIC_ANALYSIS_TYPE = Object.freeze({
    subscription: 0,
    initialAnalysis: 1,
    genericAnalysis: 2,
    followupAnalysis: 4,
  })

  export const GENERIC_ANALYSIS_STATUS = Object.freeze({
    new: 0,
    pending: 1,
    filled: 2,
    closed: 3,
  })
  