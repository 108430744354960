import { LANGUAGES, getDateFormatted, useRouteState } from '@shared/utils'
import { SRPreSubWizard } from '@sr/ui'
import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { TestScriptRouteState } from './state'
import { useLayoutContext } from '../layout-provider'
import { ROUTE_PATH } from 'apps/social-referent/src/routes/enums'
import { TranscriptionScript } from '@shared/ui'

type TestScriptProps = {
  userAreaUrl: string
}

export const TestScript: FC<TestScriptProps> = observer(({ userAreaUrl }) => {
  const { t, i18n } = useTranslation()
  const { id: userAreaId, testId } = useParams()
  const navigate = useNavigate()
  const { isLoading, test, user } = useRouteState(TestScriptRouteState, {
    testId,
  })

  const { setBackButtonText, setIsLoading } = useLayoutContext()

  const onComplete = () => {
    navigate(
      ROUTE_PATH.userAnalysis({
        userAreaUrl: `${userAreaUrl}/${userAreaId}`,
        testId,
      }),
    )
  }

  const onExit = () => {
    navigate('../')
  }

  useEffect(() => {
    if (test) {
      setBackButtonText(t(`analysis:testScript.${test?.testTypeName}.title`))
    }
  }, [test])

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])

  useEffect(() => {
    i18n.loadLanguages(Object.values(LANGUAGES))
  }, [])

  const scriptList: string[] =
    t(`analysis:testScript.${test?.testTypeName}.scriptList`, {
      returnObjects: true,
      lng: test?.person?.preferredLanguage,
    }) || []

  if (!test || !user) return

  return (
    <SRPreSubWizard
      hidePrev
      onComplete={onComplete}
      onExit={onExit}
      completeText={t(`analysis:testScript.${test?.testTypeName}.initText`)}
    >
      {scriptList.map((literal) => {
        return (
          <TranscriptionScript
            key={literal}
            script={literal}
            scriptProps={{
              clientName: test?.person?.fullName,
              socialReferent: user?.fullName,
              birthDay: getDateFormatted(test?.person?.birthDay, 'DD/MM/yyyy'),
            }}
          />
        )
      })}
    </SRPreSubWizard>
  )
})
