import {
  APPOINTMENT_TYPES_TRANSLATION,
  LEGEND_TYPES,
} from '@shared/data-access'
import {
  CalendarAdd,
  RequestQuote,
  Home,
  Phone,
  NonCertified,
  UpdateNow,
  ToolBox,
  Badge,
} from '@carbon/icons-react'
import { Images } from '@shared/assets'

export const LEGEND_ONE = Object.freeze([
  LEGEND_TYPES.initialReportOnline,
  LEGEND_TYPES.initialReportFaceToFace,
  LEGEND_TYPES.welcomeCall,
  LEGEND_TYPES.initialAnalysisCall,
  LEGEND_TYPES.initialAnalysisOnline,
  LEGEND_TYPES.initialAnalysisFaceToFace,
  LEGEND_TYPES.absences,
])

export const LEGEND_TWO = Object.freeze([
  LEGEND_TYPES.consultation,
  LEGEND_TYPES.followUpCall,
  LEGEND_TYPES.service,
  LEGEND_TYPES.genericAnalysis,
])

export const LEGEND_TRANSLATIONS = Object.freeze({
  [LEGEND_TYPES.initialReportOnline]: 'agenda.legend.initialReportOnline',
  [LEGEND_TYPES.initialReportFaceToFace]:
    'agenda.legend.initialReportFaceToFace',
  [LEGEND_TYPES.welcomeCall]:
    APPOINTMENT_TYPES_TRANSLATION[LEGEND_TYPES.welcomeCall],
  [LEGEND_TYPES.initialAnalysisCall]: 'agenda.legend.initialAnalysisCall',
  [LEGEND_TYPES.initialAnalysisOnline]: 'agenda.legend.initialAnalysisAIOnline',
  [LEGEND_TYPES.initialAnalysisFaceToFace]:
    'agenda.legend.initialAnalysisAIFaceToFace',
  [LEGEND_TYPES.absences]: APPOINTMENT_TYPES_TRANSLATION[LEGEND_TYPES.absences],
  [LEGEND_TYPES.consultation]:
    APPOINTMENT_TYPES_TRANSLATION[LEGEND_TYPES.consultation],
  [LEGEND_TYPES.followUpCall]:
    APPOINTMENT_TYPES_TRANSLATION[LEGEND_TYPES.followUpCall],
  [LEGEND_TYPES.service]: APPOINTMENT_TYPES_TRANSLATION[LEGEND_TYPES.service],
  [LEGEND_TYPES.genericAnalysis]:
    APPOINTMENT_TYPES_TRANSLATION[LEGEND_TYPES.genericAnalysis],
})

const InitialReportIcon = ({ width, height }) => (
  <Images.InitialReport width={width} height={height} />
)

export const LEGEND_ICONS = Object.freeze({
  [LEGEND_TYPES.initialReportOnline]: <CalendarAdd size={16} />,
  [LEGEND_TYPES.initialReportFaceToFace]: <Home size={16} />,
  [LEGEND_TYPES.welcomeCall]: <Phone size={16} />,
  [LEGEND_TYPES.initialAnalysisCall]: (
    <InitialReportIcon width={16} height={16} />
  ),
  [LEGEND_TYPES.initialAnalysisOnline]: (
    <InitialReportIcon width={16} height={16} />
  ),
  [LEGEND_TYPES.initialAnalysisFaceToFace]: (
    <InitialReportIcon width={16} height={16} />
  ),
  [LEGEND_TYPES.absences]: <NonCertified size={16} />,
  [LEGEND_TYPES.consultation]: <RequestQuote size={16} />,
  [LEGEND_TYPES.followUpCall]: <UpdateNow size={16} />,
  [LEGEND_TYPES.service]: <ToolBox size={16} />,
  [LEGEND_TYPES.genericAnalysis]: <Badge size={16} />,
})
