import { GLOBAL_ENVIRONMENT, getDateFormatted } from '@shared/utils'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { PersonDataParsed, Recommendations, ReportData } from '../types'
import { loadLang } from '../../../hooks'
import { useTranslation } from 'react-i18next'
import {
  getExecutiveSummaryParsed,
  getPersonDataParsed,
  getScores,
} from './utils'
import { ExecutiveSummaryValues, Scores } from './types'
import { ReportType, ReportTypeName } from '../enums'

type ReportPdfProviderProps = {
  type: string
  data: ReportData
  children: JSX.Element | JSX.Element[]
}

export type ReportPdfContextProps = {
  testDate: string
  data: ReportData | null
  person: PersonDataParsed | null
  type: string
  scores: Scores | null
  t: (key: string, attrs?: object) => string
  recommendations: Recommendations | null
  isCare: boolean
  executiveSummary: ExecutiveSummaryValues | object
}

const ReportPdfContext = createContext<ReportPdfContextProps>({
  testDate: '',
  data: null,
  person: null,
  type: '',
  scores: null,
  t: () => '',
  recommendations: null,
  isCare: false,
  executiveSummary: {},
})

export const ReportPdfProvider = ({
  type,
  data,
  children,
}: ReportPdfProviderProps) => {
  const [reportData, setReportData] = useState(data)
  const person = getPersonDataParsed(data)
  const scores = getScores(data)
  const executiveSummary = getExecutiveSummaryParsed(data)

  const isCare =
    type === ReportTypeName[ReportType.care as keyof typeof ReportTypeName]

  const { t: translate, i18n } = useTranslation()

  useEffect(() => {
    loadLang(i18n)
  }, [])

  const t = useCallback(
    (key: string, attrs?: object) =>
      translate(key, {
        ...attrs,
        lng: person.preferredLanguage,
        ns: `report-${type}`,
      }),
    [],
  )

  useEffect(() => {
    setReportData(data)
  }, [data])

  const value = useMemo(
    () => ({
      testDate: getDateFormatted(data.results.testDate, 'DD/MM/YYYY'),
      data: reportData,
      person,
      t,
      type,
      isCare,
      scores,
      recommendations:
        reportData?.results?.initialAnalysisList?.recommendations,
      executiveSummary,
    }),
    [data],
  )

  if (process.env['NX_ENV'] === GLOBAL_ENVIRONMENT.test) {
    console.table([
      ['ALCOHOL', ...value.recommendations.ALCOHOL],
      ['AUDITORY', ...value.recommendations.AUDITORY],
      ['COGNITIVE', ...value.recommendations.COGNITIVE],
      ['EMOTIONAL', ...value.recommendations.EMOTIONAL],
      ['NUTRITIONAL', ...value.recommendations.NUTRITIONAL],
      ['OCULAR', ...value.recommendations.OCULAR],
      ['PHYSICAL_ACTIVITY', ...value.recommendations.PHYSICAL_ACTIVITY],
      ['SOCIAL', ...value.recommendations.SOCIAL],
      ['TOBACCO', ...value.recommendations.TOBACCO],
    ])
  }

  return (
    <ReportPdfContext.Provider value={value}>
      {children}
    </ReportPdfContext.Provider>
  )
}

export const useReportPdfContext = () => {
  const context = useContext(ReportPdfContext)

  return context
}
