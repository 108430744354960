import {
  GenericAnalysisModel,
  GenericAnalysisResults,
  GenericAnalysisTest,
} from '@sr/data-access'
import {
  TestWithScript,
  actionsCampaign,
  actionsAnalysis,
} from '@sr/routes/common'
import { flow, getEnv, types } from 'mobx-state-tree'
import { GA_TEST_TYPE_NAME, GA_TEST_TYPE_SCRIPT, VALIDATE_ALL } from './enums'
import { sortByKey } from '@shared/utils'
import { pdf } from '@react-pdf/renderer'
import { ReportPdf, ReportTypeName, loadNs } from '@shared/ui'
import { ModalType } from './hooks'
import { BaseRouteState, PersonModel } from '@shared/data-access'
import i18next from 'i18next'

export const AnalysisRouteState = BaseRouteState.named('AnalysisRouteState')
  .props({
    modalType: types.maybeNull(types.string),
    personId: types.string,
    testId: types.string,
    testList: types.maybeNull(
      types.array(types.reference(GenericAnalysisTest)),
    ),
    testSelectedForValidate: types.maybeNull(types.string),
    testResults: types.maybeNull(types.reference(GenericAnalysisResults)),
    test: types.maybeNull(types.reference(GenericAnalysisModel)),
    person: types.maybeNull(types.reference(PersonModel)),
    reportType: types.number,
    optionPostal: types.optional(types.string, 'disabled'),
    optionEmail: types.optional(types.boolean, false),
    datePostal:types.maybeNull(types.string),
    dateEmail:types.maybeNull(types.string),
    generateInform: types.maybeNull(types.boolean),
  })
  .views((self) => ({
    get testListOrdered() {
      return sortByKey({ array: self.testList?.slice(), key: 'testOrder' })
    },
    get typeList() {
      return self.testListOrdered?.map((test) => ({
        ...test,
        name: GA_TEST_TYPE_NAME[test.testType],
        script: GA_TEST_TYPE_SCRIPT[test.testType],
        scriptProps: {
          province: self.person?.region,
          town: self.person?.locality,
        },
        autoValidate:
          test.id === self.testSelectedForValidate ||
          self.testSelectedForValidate === VALIDATE_ALL,
        Component: TestWithScript,
      }))
    },
    get resultsParsed() {
      if (!self.testResults?.parsed) return {}

      return {
        results: {
          initialAnalysisList: self.testResults?.parsed,
          testDate: self.testResults?.genericAnalysisDate,
          modificationDate: self.testResults?.genericAnalysisResultsDate,
        },
      }
    },
  }))
  .actions((self) => ({
    afterAttach() {
      self.loadDependencies()
    },
    loadDependencies: flow(function* loadDependencies() {
      self.setLoading('AnalysisCampaignsRouteState')
      try {
        yield self.loadTest()
        yield self.loadPerson()
        yield self.loadTestsList({ onError: self.onError })
      } finally {
        self.removeLoading('AnalysisCampaignsRouteState')
      }
    }),
    sendReport: flow(function* sendReport() {
      self.setLoading('sendReport')
      try {
        yield loadNs({
          i18n: i18next,
          ns: [
            'initial-report',
            `report-${ReportTypeName[self.test?.genericAnalysisType]}`,
          ],
        })
        if (!self.testResults) {
          yield self.generateTestResults({ onError: self.onError })
        }

        const myPdf = pdf(
          <ReportPdf type={self.reportType} results={self.resultsParsed} />,
        )

        const blob = yield myPdf.toBlob().catch((error) => console.log(error))
        const formData = new FormData()
        formData.append('pdf', blob)

        yield getEnv(self).client.post(
          `generic_analysis_results/${self.testResults.id}/send_prevention_plan_report/`,
          formData,
          {
            headers: {
              'Content-Type': `multipart/form-data`,
            },
          },
        )

      } catch (error) {
        self.setModal(ModalType.sendError)
      } finally {
        self.removeLoading('sendReport')
      }
    }),  
    fetchHistoricalSentByPortal: flow(function* fetchHistoricalSentByPortal(id) {
      self.setLoading('fetchHistoricalSentByPortal')
      try {
        const response = yield getEnv(self).client.get(`generic_analysis/${id}/get_last_historical_sent_by_type?type=0`)
        self.historicalPortal = response.data

      } catch (error) {
        console.error('Error Fetching last Historical Portal sent data:', error)
        self.setModal(ModalType.genericError)
      } finally {
        self.removeLoading('fetchHistoricalSentByPortal')
      }
    }),
    sendByPortal: flow(function* sendByPostal(testId) {
      self.setLoading('sendByPostal')
      try {
        const currentDate = new Date().toISOString().split('T')[0]
        yield getEnv(self).client.post(
          `generic_analysis/${testId}/historical_sent/`,
          {
            data: {
              type: 'HistoricalSent',
              attributes: {
                type: 0,
                sent_date: currentDate,
              },
            },
          },
        )
      } catch (error) {
        self.setModal(ModalType.sendError)
      } finally {
        self.removeLoading('sendByPostal')
      }
    }),
    fetchHistoricalSentByEmail: flow(function* fetchHistoricalSentByEmail(id) {
      self.setLoading('fetchHistoricalSentByEmail')
      try {
        const response = yield getEnv(self).client.get(`generic_analysis/${id}/get_last_historical_sent_by_type?type=1`)

        const dataResponsed = response.data.data
        if(dataResponsed) {
          const lastItem = dataResponsed[dataResponsed.length - 1]
          const attributes = lastItem?.attributes

          if(attributes?.sentDate) {
            self.optionEmail = true
            self.dateEmail = attributes.sentDate
          } else {
            self.optionEmail = false
            self.dateEmail = null
          }
        } else {
          self.optionEmail = false
        }

        self.historicalEmail = response.data
      } catch (error) {
        console.error('Error Fetching last Historical Email sent data:', error)
        self.setModal(ModalType.genericError)
      } finally {
        self.removeLoading('fetchHistoricalSentByEmail')
      }
    }),
    sendByEmail: flow(function* sendByPostal() {
      self.setLoading('sendByEmail')
      try {
        yield loadNs({
          i18n: i18next,
          ns: [
            'initial-report',
            `report-${ReportTypeName[self.test?.genericAnalysisType]}`,
          ],
        })
        if (!self.testResults) {
          yield self.generateTestResults({ onError: self.onError })
        }

        const myPdf = pdf(
          <ReportPdf type={self.reportType} results={self.resultsParsed} />,
        )

        const blob = yield myPdf.toBlob().catch((error) => console.log(error))
        const formData = new FormData()
        formData.append('pdf', blob)

        yield getEnv(self).client.post(
          `generic_analysis_results/${self.testResults.id}/send_generic_analysis_pdf_report/`,
          formData,
          {
            headers: {
              'Content-Type': `multipart/form-data`,
            },
          },
        )
      } catch (error) {
        self.setModal(ModalType.sendError)
      } finally {
        self.removeLoading('sendByEmail')
      }
    }),
    fetchHistoricalSentByPostal: flow(function* fetchHistoricalSentByPostal(id) {
      self.setLoading('fetchHistoricalSentByPostal')
      try {
        const response = yield getEnv(self).client.get(`generic_analysis/${id}/get_last_historical_sent_by_type?type=2`)
        const dataResponsed = response.data.data
        if(dataResponsed) {
          const lastItem = dataResponsed[dataResponsed.length - 1]
          const attributes = lastItem?.attributes

          if(attributes?.sentDate) {
            self.optionPostal = 'on'
            self.datePostal = attributes.sentDate
          } else {
            self.optionPostal = 'off'
            self.datePostal = null
          }
        } else {
          self.optionPostal = 'off'
          self.datePostal = null
        }

        self.historicalPostal = response.data

      } catch (error) {
        console.error('Error Fetching last Historical Postal sent data:', error)
        self.setModal(ModalType.genericError)
      } finally {
        self.removeLoading('fetchHistoricalSentByPostal')
      }
    }),
    sendByPostal: flow(function* sendByPostal(id) {
      self.setLoading('sendByPostal')
      try {
        const currentDate = new Date().toISOString().split('T')[0]
        yield getEnv(self).client.post(
          `generic_analysis/${id}/historical_sent/`,
          {
            data: {
              type: 'HistoricalSent',
              id: id,
              attributes: {
                type: 2,
                sent_date: currentDate,
              },
            },
          },
        )
      } catch (error) {
        self.setModal(ModalType.sendError)
      } finally {
        self.removeLoading('sendByPostal')
      }
    }),
    setAutoValidate(id) {
      self.testSelectedForValidate = id
    },
    setModal(modalType) {
      self.modalType = modalType
    },
    reload() {
      self.modalType = null
      self.loadDependencies()
    },
    setEnableOptionsPostal() {
      self.generateInform = true
      if(self.datePostal) {
        self.optionPostal  = 'on'
      } else {
        self.optionPostal  = 'off'
      }
      self.optionEmail = true
    },
    resend() {
      self.modalType = null
      self.sendReport()
      self.setEnableOptionsPostal()
    },
    onError() {
      self.setModal(ModalType.genericError)
    },
    ...actionsAnalysis(self),
    ...actionsCampaign(self),
  }))
