export type SlotDate = {
  startDate: string
  endDate: string
}

export type Slot = {
  slotType: number
} & SlotDate

export type DaySlot = {
  isUnavailable: boolean
} & SlotDate

export type SingleSchedule = {
  day: JSX.Element
  daySlots: DaySlot[]
}[]

export type Schedule = SingleSchedule[]

export type UseSchedule = (slots: Slot[]) => Schedule
