import { ReactElement, FC } from 'react'
import { Page, View, StyleSheet } from '@react-pdf/renderer'
import { useReportPdfContext } from '../../provider'
import { LayoutPage } from '../../components'
import { tw } from '../../utils'

interface SectionIndexContentProps {
  mainCover: ReactElement
  content: ReactElement
  introduction: ReactElement
}

export const SectionIndexContent: FC<SectionIndexContentProps> = ({
  mainCover,
  content,
  introduction,
}) => {
  const { t } = useReportPdfContext()

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      fontFamily: 'Montserrat',
    },
  })

  return (
    <>
      <Page style={styles.page} size="A4">
        <View style={tw('pt-16')}>{mainCover}</View>
      </Page>
      <LayoutPage title={t('initial-report:sectionOne.indexContent.title')}>
        {content}
      </LayoutPage>
      <LayoutPage
        title={t('initial-report:sectionOne.introduction.header')}
        noSpacing
      >
        {introduction}
      </LayoutPage>
    </>
  )
}

export default SectionIndexContent
