module.exports = {
  colors: {
    primary: 'var(--primary-color)',
    'primary-light': '#B4C6C4',
    secondary: 'var(--secondary-color)',
    'dark-red': '#F36A25',
    'policy-state': '#F36A25',
    gainsboro: '#D9D9D9',
    success: '#226F54',
    error: '#DA2C38',
    'error-10': 'rgba(231, 29, 29, 0.1)',
    'pending': '#F36A25',
    link: '#0000FF',
    lightGray: '#E5E5E5',
    mediumGray: '#71717A',
    // Recordar añadir también en la "safelist" para que funcionen
    appointment: {
      'initial-report': {
        base: '#F4A261',
        tone: '#C3824E',
      },
      'monitoring-report': {
        base: '#F2CC8F',
        tone: '#D5B98B',
      },
      consultation: {
        base: '#9A8C98',
        tone: '#938992',
      },
      'initial-analysis': {
        base: '#B5838D',
        tone: '#A8828A',
      },
      'initial-report-face-to-face': {
        base: '#EE7766',
        tone: '#be5f52',
      },
      'welcome-call': {
        base: '#B5838D',
        tone: '#916971',
      },
      service: {
        base: '#C9CBA3',
        tone: '#A1A282',
      },
      absences: {
        base: '#81B29A',
        tone: '#81A593',
      },
      'initial-analysis-call': {
        base: '#52796F',
        tone: '#426159',
      },
      'initial-analysis-online': {
        base: '#84A98C',
        tone: '#6a8770',
      },
      'initial-analysis-face-to-face': {
        base: '#95D5B2',
        tone: '#77aa8e',
      },
      'generic-analysis': {
        base: '#355070',
        tone: '#2a405a',
      },
      'free-slot': '#F4F1DE',
    },
    campaign: {
      pending: '#F36A25',
      accepted: '#226F54',
      cancelled: '#E71D1D',
      finished: '#71717A',
      detached: '#E71D1D',
    },
  },
  fontFamily: {
    raleway: 'Raleway',
  },
  height: {
    'full-height-with-title': 'calc(100% - 132px)',
    'full-height-without-title': 'calc(100% - 64px)',
  },
}
