import { Text, View } from '@react-pdf/renderer'
import { tw } from '../../../utils'
import { useReportPdfContext } from '../../../report-pdf-provider'
import { ReportType } from '../../../../report-pdf/enums'
export const SecondPage = () => {
  const { t, type } = useReportPdfContext()

  const planType = t('planType')

  const indexContent = [
    {
      title: t('initial-report:sectionOne.indexContent.one.title'),
      text: t('initial-report:sectionOne.indexContent.one.text', {
        planType,
      }),
    },
    {
      title: t('initial-report:sectionOne.indexContent.two.title'),
      text: t('initial-report:sectionOne.indexContent.two.text', {
        planType,
      }),
    },
    {
      title: t('initial-report:sectionOne.indexContent.three.title'),
      text: t('initial-report:sectionOne.indexContent.three.text'),
    },
    ...(ReportType[type] === ReportType.care
      ? [
          {
            title: t('initial-report:sectionOne.indexContent.four.title'),
            text: t('initial-report:sectionOne.indexContent.four.text', {
              planType,
            }),
          },
        ]
      : []),

    {
      title: t('initial-report:sectionOne.indexContent.five.title'),
      text: t('initial-report:sectionOne.indexContent.five.text', {
        planType,
      }),
    },
    {
      title: t('initial-report:sectionOne.indexContent.six.title'),
      text: t('initial-report:sectionOne.indexContent.six.text'),
    },
  ]

  return (
    <View
      style={{
        ...tw('flex flex-row justify-between flex-wrap gap-8 pt-8 w-full'),
      }}
    >
      {indexContent.map((content, index) => {
        return (
          <View
            key={index}
            style={{
              ...tw('flex flex-col gap-4'),
              width: '45%',
            }}
          >
            <View style={{ ...tw('flex flex-row items-center gap-4') }}>
              <Text style={{ ...tw('font-bold text-black'), fontSize: '14px' }}>
                {index + 1}
              </Text>
              <View style={{ ...tw('w-1 h-8 bg-secondary rounded-full') }} />
              <Text
                style={{
                  ...tw('font-bold text-black'),
                  fontSize: '14px',
                }}
              >
                {content.title}
              </Text>
            </View>
            <Text
              style={{
                ...tw(`text-black ${index === 0 ? 'ml-11' : 'ml-12'}`),
                fontSize: '11px',
              }}
            >
              {content.text}
            </Text>
          </View>
        )
      })}
    </View>
  )
}
