import { FC } from 'react'
import {
  DisclaimerBanner,
  LayoutPage,
  ListItem,
  SectionSummaryBlock,
  SubSection,
  TranscriptionScriptPDF,
} from '../../components'
import { useReportPdfContext } from '../../provider'
import { AreaColor, tw } from '../../utils'

type ExecutiveSummaryProps = {
  number: number
}

export const ExecutiveSummary: FC<ExecutiveSummaryProps> = ({ number }) => {
  const { t, executiveSummary } = useReportPdfContext()

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const listOfTranslations: string[] = t(
    'initial-report:executiveSummary.list.items',
    {
      returnObjects: true,
    },
  )

  const list = Array.isArray(listOfTranslations)
    ? listOfTranslations.map((literal) => {
        return <TranscriptionScriptPDF key={literal} script={literal} />
      })
    : []

  const summaryList = Object.keys(executiveSummary)

  return (
    <LayoutPage
      title={t('initial-report:executiveSummary.title', { number })}
      headerText={t('reportTitle')}
    >
      <SubSection>
        <TranscriptionScriptPDF
          style={tw('mb-4')}
          script="initial-report:executiveSummary.description"
        />
        <TranscriptionScriptPDF
          style={tw('mb-4')}
          script="initial-report:executiveSummary.list.title"
        />
        <ListItem style={tw('mb-8')} list={list} />
        {summaryList.map((section, ix) => {
          return (
            <SectionSummaryBlock
              key={`section-summary-${section}-${ix}`}
              number={ix + 1}
              color={AreaColor[section as keyof typeof AreaColor]}
              title={t(`initial-report:sectionTitle.${section}`)}
              sectionLink={`#section-${section}`}
              worksheetLink={`#worksheet-${section}`}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              content={executiveSummary[section]?.content}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              level={executiveSummary[section]?.score}
            />
          )
        })}
        <DisclaimerBanner />
      </SubSection>
    </LayoutPage>
  )
}
