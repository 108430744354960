import {
  EVENT_FORM,
  getAppointmentModalityOptions,
  getAppointmentOptions,
  getChangeOriginOptions,
  getChangeReasonOptions,
  getSRAppointmentOptions,
} from '../../common'
import {
  SRInput,
  SRSelect,
  SRTimeInput,
  SRSearchSelect,
  SRFormAdress,
} from '@sr/ui'
import { useModalContext } from '../../provider'
import { FORM_TYPE } from '@shared/ui'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { APPOINTMENT_MODALITY, APPOINTMENT_TYPES } from '@shared/data-access'

export const CareForm = observer(
  ({
    isCoordinator,
    isReadOnly,
    isEdit,
    policyClients = [],
    isEditing,
    needsTracking,
    searchSelectProps,
    officeStreet,
  }) => {
    const { t } = useTranslation()
    const {
      appointmentType,
      noDigital,
      street,
      officeControl,
      modality,
    } = useModalContext()

    const appointmentTypes =
      isCoordinator || isReadOnly
        ? getAppointmentOptions(isEdit)
        : getSRAppointmentOptions(isEdit)

    const policiesEmptyError =
      !policyClients.length && t('agenda.formModal.formError.noUserList')

    const defaultLabel = t('agenda.formModal.labels.userList')

    const isBlocked = isEdit && !isEditing

    const hasToAddReason = needsTracking && isEditing

    return (
      <>
        <SRSelect
          name={EVENT_FORM.appointmentType}
          options={appointmentTypes}
          disabled={isReadOnly || isBlocked}
        />
        <SRSelect
          name={EVENT_FORM.appointmentModality}
          options={getAppointmentModalityOptions(appointmentType)}
          disabled={isReadOnly || isBlocked}
        />
        <SRInput
          name={EVENT_FORM.appointmentDay}
          type={FORM_TYPE.date}
          min={new Date().toISOString().split('T')[0]}
          disabled={isReadOnly || isBlocked}
        />
        <SRTimeInput
          startName={EVENT_FORM.startDate}
          endName={EVENT_FORM.endDate}
          disabled={isReadOnly || isBlocked}
        />
        <SRSearchSelect
          {...searchSelectProps}
          name={EVENT_FORM.appointmentPolicy}
          options={policyClients}
          disabled={isBlocked || isReadOnly || isEdit}
          emptyError={policiesEmptyError}
          defaultLabel={defaultLabel}
        />
        {hasToAddReason && (
          <>
            <SRSelect
              name={EVENT_FORM.changeOrigin}
              options={getChangeOriginOptions()}
              disabled={isReadOnly || isBlocked}
              placeholder={t('agenda.formModal.placeholder.changeOrigin')}
            />
            <SRSelect
              name={EVENT_FORM.changeReason}
              options={getChangeReasonOptions()}
              disabled={isReadOnly || isBlocked}
              placeholder={t('agenda.formModal.placeholder.changeReason')}
            />
          </>
        )}
        {(noDigital ||
          (appointmentType === APPOINTMENT_TYPES.INITIAL_ANALYSIS &&
            modality === APPOINTMENT_MODALITY.faceToFace)) && (
          <SRFormAdress
            officeControl={officeControl}
            officeAddress={officeStreet}
            homeAddress={street}
          />
        )}
      </>
    )
  },
)
