// COLORS
// Common buttons

export const baseBtnClass = `flex items-center space-x-2 px-4 py-2 rounded-lg focus:outline-none focus:ring`
export const btnPrimary = `${baseBtnClass} bg-generic-primary-500 hover:bg-generic-primary-400 active:bg-generic-primary-600 disabled:bg-generic-primary-100  focus:bg-generic-primary-500 focus:ring-generic-primary-200`
export const btnSecondary = `${baseBtnClass} bg-white text-generic-primary-500 border border-generic-primary-500 hover:bg-generic-primary-50 active:bg-generic-primary-100  focus:bg-white focus:ring-generic-primary-200 disabled:border-generic-primary-200 disabled:cursor-not-allowed`
export const btnTertiary = `${baseBtnClass} bg-white hover:bg-generic-primary-50 active:bg-generic-primary-100 focus:bg-white focus:ring-generic-primary-200 disabled:text-generic-primary-200 disabled:bg-white`

//Icon buttons

export const baseBtnIcon = `flex items-center justify-center rounded-lg w-10 h-10 focus:outline-none focus:ring`
export const btnIconPrimary = `${baseBtnIcon} bg-generic-primary-500 hover:bg-generic-primary-400 active:bg-generic-primary-600 disabled:bg-generic-primary-100  focus:bg-generic-primary-500 focus:ring-generic-primary-200`
export const btnIconSecondary = `${baseBtnIcon} bg-white border border-generic-primary-500 hover:bg-generic-primary-50 active:bg-generic-primary-100  focus:bg-white focus:ring-generic-primary-200 disabled:border-generic-primary-200 disabled:cursor-not-allowed`
export const btnIconTertiary = `${baseBtnIcon} bg-white hover:bg-generic-primary-50 active:bg-generic-primary-100 focus:bg-white focus:ring-generic-primary-200 disabled:text-generic-primary-200 disabled:bg-white`

// Floating Action Buttons

export const btnFAB = `${btnIconPrimary} w-14 h-14`
