import { SocialReferent } from 'libs/shared/data-access/src/lib/social-referent/types'
import { Campaign } from '../campaign'
import { Product } from '../product'
import { Collective } from '../collective'
import { Person } from '@shared/data-access'

export interface Annuity {
  id: number
  contractNumber?: null | string
  policyNumber?: null | string
  policyProduct?: null | string
  person?: null | Person
  socialReferent?: null | SocialReferent
  socialReferentId?: null | string
  analysisDate?: null | string
  campaign?: null | Campaign
  product?: null | Product
  collective?: null | Collective
}
