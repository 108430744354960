import { CampaignReport, CareReport } from './reports'

export enum ReportType {
  care = 1,
  campaign = 2,
}

export const ReportTypeName = Object.freeze({
  [ReportType.care]: 'care',
  [ReportType.campaign]: 'campaign',
})

export const ReportComponent = Object.freeze({
  [ReportType.care]: CareReport,
  [ReportType.campaign]: CampaignReport,
})
