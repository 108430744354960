import {
  APPOINTMENT_MODALITY,
  APPOINTMENT_MODALITY_TRANSLATION,
  APPOINTMENT_MODIFICATION_ORIGIN,
  APPOINTMENT_MODIFICATION_REASON,
  APPOINTMENT_ORIGIN_TRANSLATION,
  APPOINTMENT_REASON_TRANSLATION,
  APPOINTMENT_TYPES,
  APPOINTMENT_TYPES_TRANSLATION,
} from '@shared/data-access'
import { t } from 'i18next'

export const getDefaultModality = ({ type, noDigital = false }) => {
  const modalityType = noDigital
    ? APPOINTMENT_MODALITY.faceToFace
    : APPOINTMENT_MODALITY.online

  const defaultPhoneModalities = [
    APPOINTMENT_TYPES.MONITORING_REPORT,
    APPOINTMENT_TYPES.CONSULTATION,
    APPOINTMENT_TYPES.WELCOME_CALL,
  ]

  return defaultPhoneModalities.some((defaultType) => type === defaultType)
    ? APPOINTMENT_MODALITY.phoneCall
    : modalityType
}

export const getAppointmentOptions = (isEdit) => {
  const disabledTypes = [
    APPOINTMENT_TYPES.INITIAL_REPORT,
    APPOINTMENT_TYPES.INITIAL_REPORT_FACE_TO_FACE,
    APPOINTMENT_TYPES.WELCOME_CALL,
    APPOINTMENT_TYPES.SERVICE_APPOINTMENT,
    APPOINTMENT_TYPES.GENERIC_ANALYSIS,
  ]

  return Object.values(APPOINTMENT_TYPES).map((value) => {
    return {
      value,
      name: t(APPOINTMENT_TYPES_TRANSLATION[value]) || null,
      disabled: disabledTypes.some((type) => type === value) || isEdit,
    }
  })
}

export const getAbsencesOptions = () => {
  return [
    {
      value: APPOINTMENT_TYPES.ABSENCES,
      name:
        t(APPOINTMENT_TYPES_TRANSLATION[APPOINTMENT_TYPES.ABSENCES]) || null,
    },
  ]
}

export const getSRAppointmentOptions = (isEdit) => {
  const srCanEditOrCreate = [
    APPOINTMENT_TYPES.MONITORING_REPORT,
    APPOINTMENT_TYPES.CONSULTATION,
    APPOINTMENT_TYPES.INITIAL_ANALYSIS,
    APPOINTMENT_TYPES.GENERIC_ANALYSIS,
    APPOINTMENT_TYPES.WELCOME_CALL,
  ]

  return getAppointmentOptions(isEdit).filter(({ value }) => {
    return srCanEditOrCreate.some((type) => type === value)
  })
}

const isOnlyPhone = ({ modality, appointmentType }) => {
  const modalities = [
    APPOINTMENT_MODALITY.faceToFace,
    APPOINTMENT_MODALITY.online,
  ]
  const onlyPhoneAppointments = [
    APPOINTMENT_TYPES.MONITORING_REPORT,
    APPOINTMENT_TYPES.CONSULTATION,
  ]
  return (
    modalities.some((mod) => mod === modality) &&
    onlyPhoneAppointments.some((type) => type === appointmentType)
  )
}

export const getAppointmentModalityOptionsGeneric = () => {
  return Object.values(APPOINTMENT_MODALITY).map((value) => {
    return {
      value,
      name: t(APPOINTMENT_MODALITY_TRANSLATION[value]) || null,
      disabled: value === APPOINTMENT_MODALITY.faceToFace,
    }
  })
}

export const getAppointmentModalityOptions = (appointmentType) => {
  return Object.values(APPOINTMENT_MODALITY).map((value) => {
    return {
      value,
      name: t(APPOINTMENT_MODALITY_TRANSLATION[value]) || null,
      disabled: isOnlyPhone({ modality: value, appointmentType }),
    }
  })
}

export const getChangeOriginOptions = () => {
  const APPOINTMENT_ORIGIN = [
    APPOINTMENT_MODIFICATION_ORIGIN.fromClient,
    APPOINTMENT_MODIFICATION_ORIGIN.fromQida,
  ]

  return Object.values(APPOINTMENT_ORIGIN).map((value) => {
    return {
      value,
      name: t(APPOINTMENT_ORIGIN_TRANSLATION[value]) || null,
    }
  })
}

export const getChangeReasonOptions = () => {
  return Object.values(APPOINTMENT_MODIFICATION_REASON).map((value) => {
    return {
      value,
      name: t(APPOINTMENT_REASON_TRANSLATION[value]) || null,
    }
  })
}
